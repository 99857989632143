import "./styles/Title.css"

export default function Title() {
	return (
		<div id="titleHeaderDiv">
			<h1>Ranked Records</h1>
			<h3>
				Create Spotify playlists with all the songs by your favourite
				artists, ranked.
			</h3>
		</div>
	)
}
